






















































































































































































































































































/**
 * Show all recorded calls
 */
import Vue from "vue";
import RecordPlayer from "./RecordPlayer.vue";
import NotRecords from "@/components/NotRecords.vue";
import rules from "@/components/account/rules";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { CallList } from "@/mixins";
import RecordedCall from "@/models/RecordedCall";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import moment from "moment";

export default Vue.extend({
  name: "recorded-calls",
  mixins: [CallList],
  components: {
    RecordPlayer,
    NotRecords,
    MaDatePicker,
  },
  data() {
    return {
      rules: {
        email: rules.email,
        required: rules.required,
        phone: rules.isPhone,
      },
      footerProps: { "items-per-page-options": [15, 50, 100] },
      options: {},
      filternumber: null,
      filterpatient: null,
      filteremployee: null,
      filterRange: "year",
      reqbody: {
        range: {
          limit: 15,
          offset: 0,
        },
        record: true,
        dateRange: {
          date1: "",
          date2: "",
        },
        phoneNumber: null,
      },
      dateFrom: "",
      dateTo: "",

      expanded: [],
      file: "",
      admin: false,
      showPlayer: false,
      headers: [
        {
          text: this.$t("origin"),
          align: "start",
          sortable: false,
          value: "origin",
        },
        {
          text: this.$t("name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("crmCallCenter.from"),
          align: "start",
          sortable: false,
          value: "from",
        },
        {
          text: this.$t("crmCallCenter.to"),
          align: "start",
          sortable: false,
          value: "to",
        },
        {
          text: this.$t("crmCallCenter.duration"),
          align: "left",
          sortable: false,
          value: "duration",
        },
        {
          text: this.$t("labelDate"),
          align: "center",
          sortable: false,
          value: "createAt",
        },
        { text: "", value: "actions", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  mounted() {
    if (this.isAdmin || this.isSuper) {
      this.admin = true;
      (this as any).actGetEmployees();
    } else {
      this.admin = false;
    }
    (this as any).actListPatientsFilter();
  },
  computed: {
    ...mapState("crmCallCenterModule", [
      "recordList",
      "totalrecord",
      "requestC",
      "filter",
    ]),
    ...mapState("crmEmployeeModule", {
      loadingEmployees: "loading",
      employees: "workers",
    }),
    ...mapState("crmMedicFormModule", ["patients"]),
    ...mapState(["userContacts"]),
    ...mapGetters([
      "isSuper",
      "isAdmin",
      "getPhone",
      "getUser",
      "getName",
      "getpatientN",
    ]),

    validClean() {
      if (
        ((this as any).dateFrom != null &&
          (this as any).dateFrom != undefined &&
          (this as any).dateFrom != "") ||
        ((this as any).dateTo != null &&
          (this as any).dateTo != undefined &&
          (this as any).dateTo != "")
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    options(val) {
      if (val != {}) {
        (this as any).getCalls();
      }
    },
    dateFrom(val) {
      if (
        val !== null &&
        (this as any).dateTo !== null &&
        (this as any).dateTo !== ""
      ) {
        (this as any).filterRange = "custom";
        (this as any).getCalls();
      }
    },
    dateTo(val) {
      if (
        val !== null &&
        (this as any).dateFrom !== null &&
        (this as any).dateFrom !== ""
      ) {
        (this as any).filterRange = "custom";
        (this as any).getCalls();
      }
    },
    filternumber(val) {
      if (val != null) {
        (this as any).filterpatient = null;
        (this as any).filteremployee = null;
        if (val.length == 10) {
          (this as any).reqbody.phoneNumber = "+1" + val;
          (this as any).getCalls();
        } else if (val.length == 0) {
          (this as any).reqbody.phoneNumber = null;
          (this as any).getCalls();
        }
      }
    },
    filterpatient(val) {
      if (val != null) {
        (this as any).filternumber = null;
        (this as any).filteremployee = null;
        (this as any).reqbody.phoneNumber = val;
        (this as any).getCalls();
      }
    },
    filteremployee(val) {
      if (val != null) {
        (this as any).filterpatient = null;
        (this as any).filternumber = null;
        (this as any).reqbody.phoneNumber = val;
        (this as any).getCalls();
      }
    },
  },

  methods: {
    ...mapActions("crmCallCenterModule", ["actGetCallsRec"]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapActions("crmMedicFormModule", ["actListPatientsFilter"]),
    ...mapMutations("crmCallCenterModule", [
      "mutRequest",
      "mutFilter",
      "mutView",
    ]),
    ...mapMutations(["mutReqRange"]),
    playRecord(file: string): void {
      this.file = file;
      this.showPlayer = true;
    },

    getFrom(call: RecordedCall) {
      if (call.from == null || call.from == undefined) {
        return "-";
      }
      if (call.from == this.getPhone || call.from == `client:${this.getUser}`) {
        return "My selft";
      } else {
        return (this as any).cleanPhone(call.from, call);
      }
    },
    getTo(call: RecordedCall) {
      if (call.to == null || call.to == undefined) {
        return "-";
      }
      if (call.to == this.getPhone || call.to == `client:${this.getUser}`) {
        return "My selft";
      } else {
        return (this as any).cleanPhone(call.to, call);
      }
    },

    getCalls() {
      const { page, itemsPerPage }: any = (this as any).options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 15;
      (this as any).reqbody.range = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      if ((this as any).filterRange == "year") {
        (this as any).reqbody.dateRange = {
          date1: moment().startOf("year").utc().toISOString(),
          date2: moment().utc().toISOString(),
        };
      } else {
        (this as any).reqbody.dateRange = {
          date1: moment((this as any).dateFrom)
            .startOf("day")
            .utc()
            .toISOString(),
          date2: moment((this as any).dateTo)
            .endOf("day")
            .utc()
            .toISOString(),
        };
      }
      (this as any).actGetCallsRec((this as any).reqbody);
    },
    removeRecord(item: RecordedCall) {
      (this as any).setRecordToRemove(item);
      this.showPlayer = false;
    },

    _clearFilters() {
      (this as any).dateFrom = "";
      (this as any).dateTo = "";
      (this as any).filterRange = "year";
      (this as any).clearFilters();
    },
    clearFilters() {
      (this as any).filternumber = null;
      (this as any).filterpatient = null;
      (this as any).filteremployee = null;
      (this as any).reqbody.phoneNumber = null;
      (this as any).getCalls();
    },

    _origin(item: RecordedCall) {
      if (item.from == this.getPhone || item.from == `client:${this.getUser}`) {
        return "Employee";
      }

      const filt = this.userContacts.filter(
        (element: any) => element.number == item.from
      );
      if (filt.length != 0) {
        return filt[0].origin;
      }
      if (item.patient_name != null) {
        return this.getpatientN;
      }
      if (item.l_phone != null) {
        return "Lead";
      }
      if (item.employee_fullname != null) {
        return "Employee";
      }
      return "-";
    },
    _name(item: RecordedCall) {
      if (item.from == this.getPhone || item.from == `client:${this.getUser}`) {
        return this.getName;
      }

      const filt = this.userContacts.filter(
        (element: any) => element.number == item.from
      );
      if (filt.length != 0) {
        return filt[0].alias;
      }

      if (item.patient_name != null) {
        return item.patient_name;
      }
      if (item.l_name != null) {
        return item.l_name;
      }

      if (item.employee_fullname != null) {
        return item.employee_fullname;
      }
      return "-";
    },
  },
});
